<template>
  <div
      class="flex flex-col max-w-[524px] m-auto w-full pt-[31px] sm:pt-[66px] pb-[66px] px-5 sm:px-0 lg:pt-[167px]"
  >
    <a href="/sign-in" class="text-vivid-purple text-xl pb-2.5">‹ Back</a>
    <h5 class="text-[27px] sm:text-3xl/[35px] font-bold font-raleway mb-[19px]">
      Enter your One-time code.
    </h5>
    <CustomForm @submit="onSubmit" class="w-full">
      <span class="text-lg/[21px] text-charcoal font-raleway pb-3">
        Enter your one time code that was sent to:
        <span class="text-pinkish-purple">{{ contact }}</span>
      </span>
      <CodeInput v-model="code" :fields="6"/>
      <button
          :disabled="spinningContinue"
          class="mt-5 bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
      >
         <span
             v-if="spinningContinue"
             class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
         >
          <CustomLoader/>
        </span>
        <span v-else>
          Continue
        </span>
      </button>
      <div class="text-center">
        <button
            :disabled="spinningSend"
            @click="sendNewCode"
            type="button"
            class="text-vivid-purple text-lg font-semibold mt-2.5 hover:opacity-75"
        >
          <span
              v-if="spinningSend"
              class="px-[15px] cursor-pointer flex items-center rounded-small justify-center w-full text-vivid-purple gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader/>
          </span>
          <span v-else>
            Send new code
          </span>
        </button>
      </div>
    </CustomForm>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {Form as CustomForm} from 'vee-validate'
import {useSignIn} from '@stores/sign-in'
import {useAuth} from "vue3-auth";
import CodeInput from "@components/reusable/CodeInput.vue";
import CustomLoader from "@components/reusable/CustomLoader.vue";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  components: {CustomLoader, CodeInput, CustomForm},
  setup() {
    const spinningContinue = ref<boolean>(false)
    const spinningSend = ref<boolean>(false)
    const signIn = useSignIn()
    const contact = signIn.getContact()
    const code = ref<any>(null);
    const {login} = useAuth()
    const $toast = useToast()

    const sendNewCode = async () => {
      spinningSend.value = true;
      await signIn.requestCodeMakeHandler(contact)
      spinningSend.value = false;
    }

    watch(() => code.value, () => {
      onSubmit()
    })


    const onSubmit = async () => {
      spinningContinue.value = true;
      try {
        await login({
          code: code.value
        })
      } catch (e:any) {
        $toast.error(e.response.data.message)
      }
      spinningContinue.value = false;
    }

    return {
      spinningContinue,
      spinningSend,
      contact,
      sendNewCode,
      onSubmit,
      code
    }
  }
})
</script>

<style>
.code-wrap input {
  background: transparent;
  border-style: none;
  width: 8.33%;
  border-radius: 0;
  border-bottom: 1.5px solid #6d8791;
  font-size: 30px;
  padding: 8px 10px;
}

.code-wrap input:focus-visible {
  outline: none;
}
</style>
