<template>
  <div class="flex-col  flex-1" v-if="subscriptions">
    <div class="flex flex-col items-center" v-for="(subscription, key) in subscriptions">
      <Subscription
          :subscription="subscription"
          :isSingle="false"
          :key="key"
      />
    </div>
  </div>
  <div class="flex-col  flex-1" v-else>
    <div class="flex flex-col items-center flex-1 text-center">No Subscriptions Found</div>
  </div>
</template>

<script lang="ts">

import {defineComponent, onMounted, ref} from "vue";
import AccountTitle from "@components/account/AccountTitle.vue"
import AccountNavigation from "@components/navigations/AccountNavigation.vue";
import Subscription from "@components/account/Subscription.vue"
import {useUser} from "@stores/auth";

export default defineComponent({
  components: {
    AccountNavigation,
    AccountTitle,
    Subscription
  },
  setup() {
    const userStore = useUser();

    const subscriptions = ref<[] | null>(null)

    onMounted(async () => {
      await userStore.requestUserSubscriptionHandler();
      subscriptions.value = userStore.subscriptions
    });

    return {
      subscriptions
    };
  },
})
</script>

